<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">

  <div class="ibox float-e-margins">
    <div class="ibox-title">
      <h2 class="col-xs-11">
        <strong>Versamento Cassa</strong>
      </h2>
    </div>

    <div class="ibox-content">

      <tabset id="tabsType">
        <tab id="tabPayment" heading="Registro" (selectTab)="isPaymentCheck($event,'PAYMENT')"></tab>
        <tab id="tabCheck" heading="Controllo Sospesi" (selectTab)="isPaymentCheck($event,'CHECK')"></tab>
        <tab id="tabExternal" heading="Versamenti Altre Compagnie" (selectTab)="isPaymentCheck($event,'EXTERNAL')"></tab>
      </tabset>
      <br>
       
      <div class="row">

        <!--SELEZIONE FILTRI PER SERVIZIO -->
        <div class="col-lg-12">
          <form #formStatsSold="ngForm">
            <div class="row">

              <div *ngIf="isPayment == 'NEW_PAYMENT' || isPayment == 'PAYMENT'" class='col-xs-12  col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>OPERAZIONE</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" [ngModel]="isPayment"  class="form-control" id="operation" name="operation" ngModel operation #operation="ngModel" (change)="isPaymentCheck($event,$event.target.value)">
                      <option value="PAYMENT"> Controllo versamenti </option>
                      <option value="NEW_PAYMENT"> Nuovo Versamento </option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="isSoluzione1 || isPayment == 'NEW_PAYMENT'" class='col-xs-12  col-md-3 col-lg-3'>
                <div class="form-group">
                  <label>AZIENDA DI TRASPORTO</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel"
                      required>
                      <option [ngValue]="''"> Scegli compagnia </option>
                      <option *ngIf="dataCompany.length>1" [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="isPayment != 'EXTERNAL'" class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-user"></i> OPERATORE</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idUser" class="form-control" ngModel name="idUser" required idUser #idUser="ngModel">
                      <option [ngValue]="'all'"> <span  *ngIf="isPayment == 'NEW_PAYMENT'">Scegli operatore</span><span  *ngIf="isPayment != 'NEW_PAYMENT'">Tutti</span> </option>
                      <option *ngFor="let c of filterUserData" [ngValue]="c.idUser">{{c.surname}} {{c.name}} </option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="isPayment == 'NEW_PAYMENT'" class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-user"></i> TIPO INCASSO</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="paymentType" class="form-control" ngModel name="paymentType" required paymentType #paymentType="ngModel">
                      <option [ngValue]="'all'"> <span  *ngIf="isPayment == 'NEW_PAYMENT'">Scegli incasso</span> </option>
                      <option *ngFor="let pT of dataPaymentTypes" [ngValue]="pT.code">{{pT.description}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-calendar"></i> DATA INCASSO </label>
                  <input *ngIf="isPayment == 'NEW_PAYMENT'" class="form-control" id="bsDatepicker" name="bsDatepicker" required [(ngModel)]="datepickerModel"
                  ngModel bsDatepicker #bsDatepicker placement="right" autocomplete="off" [bsConfig]="bsConfig" placement="bottom" autocomplete="off" required>

                  <input *ngIf="isPayment != 'NEW_PAYMENT'" class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="daterangepickerModel"
                  ngModel bsDaterangepicker #bsDaterangepicker autocomplete="off" placement="right" [bsConfig]="bsConfig" placement="bottom" autocomplete="off" required>
                </div>
              </div>

              <div *ngIf="isPayment == 'PAYMENT'" class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-credit-card"></i> TIPOLOGIA PAGAMENTO</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="paymentType" class="form-control" ngModel name="paymentType" required paymentType #paymentType="ngModel"
                      required>
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let pT of dataPaymentTypes" [ngValue]="pT.code">{{pT.description}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="isPayment == 'NEW_PAYMENT' || isPayment == 'CHECK'" class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-calendar"></i> DATA VERSAMENTO </label>
                  <input class="form-control" id="bsDatepickerV" name="bsDatepickerV" required [(ngModel)]="datepickerModelV"
                  ngModel bsDatepicker #bsDatepickerV placement="right" autocomplete="off" [bsConfig]="bsConfigV" placement="bottom" autocomplete="off" required>
                </div>
              </div>
              
              <div *ngIf="isPayment == 'NEW_PAYMENT'" class='col-xs-12 col-sm-6 col-md-3 col-lg-3'>
                <div class='form-group'>
                  <label><i class="fas fa-calendar"></i> TOT VERSATO </label>
                  <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                    <input type="number" style="text-align:center;" class="form-control input-number"
                      value="0" min="1.00" class="form-control" id="amountToPay" name="amountToPay" required ngModel amountToPay #amountToPay="ngModel">
                    <div class="input-group-addon">€</div>
                  </div>
                </div>
              </div>

              <div *ngIf="isPayment == 'NEW_PAYMENT'" class='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group  col-sx-12 col-md-12'>
                    <button type="button" style="margin-left: 10px" [disabled]="!formStatsSold.valid" (click)="payment(formStatsSold.value,0,utils.getMyIdCompany())" class="btn btn-view btn-outline">
                      Versa
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="isPayment != 'NEW_PAYMENT'"  class='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group  col-sx-12 col-md-12'>
                    <button type="button" style="margin-left: 10px" [disabled]="!formStatsSold.valid"
                      (click)="filterSearch()" class="btn btn-view btn-outline">
                      Cerca
                    </button>
                    <button *ngIf="isPayment !== 'EXTERNAL'" type="button" class="btn btn-primary" style="margin-left: 10px;" [disabled]="dataList?.length == 0" (click)="manageExport()">
                      Esporta
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>

      </div>


      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          
          

          <ng-container *ngIf="listService.totalRows == 0">
            <td colspan="9">
              <div class="alert alert-info">
                Nessun dato presente per la ricerca effettuata
              </div>
            </td>
          </ng-container>

          <!--PER OGNI AZIENDA-->
          <ng-container *ngFor="let companyData of dataList; index as i">
            <thead>
              <tr style="cursor:pointer" *ngIf="isPayment != 'EXTERNAL'">
                <th colspan="12"><h2>{{getCompanyName(companyData.idCompany)}} ( Versati {{getSumOfUserPayments(companyData.operators) | number : '1.2-2'}} &euro;<ng-container *ngIf="isPayment == 'PAYMENT'"> - Venduto {{companyData.totalAmount | number : '1.2-2'}} &euro; - Suppl. {{companyData.totalSupplementAmount | number : '1.2-2'}} &euro; - Da versare {{getSumOfAmountToPay(companyData.operators) | number : '1.2-2'}} &euro;</ng-container>)</h2> <small></small></th>
              </tr>
            </thead>
            <thead *ngIf="isPayment != 'CHECK'">
              <tr>
                <th></th>
                <!-- <th>Transazione</th> -->
                <th>Operatore</th>
                <th>Data Vendita</th>
                <th><span *ngIf="isPayment == 'PAYMENT'">Tipo di Pagamento</span></th>
                <th class="text-right">Venduto</th>
                <th *ngIf="isPayment != 'EXTERNAL'" class="text-right">Versato</th>
                <th class="text-right">Supplemento</th>
                <th *ngIf="isPayment != 'EXTERNAL'" class="text-center" style="width: 160px;">Da Versare</th>
                <th *ngIf="isPayment == 'PAYMENT'" style="width: 160px;"></th>
              </tr>
            </thead>
            <thead *ngIf="isPayment == 'CHECK' && !companyData.operators.length">
              <tr>
                <th></th>
                <th>Operatore</th>
                <th>Data Vendita</th>
                <th>Versato il:</th>
                <th>Tipo</th>
                <th>Importo</th>
                <th>Da</th>
                <th></th>
              </tr>
            </thead>
            <ng-container *ngFor="let data of companyData.operators; index as i">
              <tr *ngIf="isPayment != 'CHECK'">
                <td style="width: 60px" class="col-md-4 text-center">
                  <button *ngIf="isPayment == 'PAYMENT'" style="width: 40px; margin: 3rem 0;" title="{{utils.checkPayment(data.paymentType)}}" type="button" class="btn btn-warning">
                    <i class="{{utils.checkIconPayment(data.paymentType)}}"></i>
                  </button>

                  <button *ngIf="isPayment != 'PAYMENT'" style="width: 40px" title="Incasso non versato" type="button" class="btn btn-danger">
                    <i class="fas fa-exclamation"></i>
                  </button>
                </td>
                <!-- <td>{{data.idTransaction}}</td> -->
                <td style="padding-left: 8px;">{{data.user}}</td>

                <td style="padding-left: 8px;">{{data.transactionDate | date:'dd-MM-yyyy' }}</td>

                <td style="padding-left: 8px;"><span *ngIf="isPayment == 'PAYMENT'">{{getPaymentTypesDescription(data.paymentType)}}</span></td>
                <td style="padding-right: 8px;" class="text-right">{{data.totAmount | number : '1.2-2'}} €</td>
                <td style="padding-right: 8px;" *ngIf="isPayment != 'EXTERNAL'" class="text-right">{{data.totPayment | number : '1.2-2'}} €</td>
                <td style="padding-right: 8px;" class="text-right">{{data.totSupplementAmount | number : '1.2-2'}} €</td>
                <td *ngIf="isPayment == 'CHECK'" class="text-center" style="font-weight: 900">{{data.amountToPay | number : '1.2-2'}} €</td>
                <td *ngIf="isPayment == 'PAYMENT'" class="text-right">

                  <div *ngIf="isPayment == 'PAYMENT'" class="input-group mb-2 mr-sm-2 mb-sm-0">

                    <input type="number" style="text-align:center;" (input)="changeValue($event.target.value, data)"  class="form-control input-number"
                      value={{data.amountToPay}} min="0.01" max={{data.maxValuePay}} class="form-control">

                    <div class="input-group-addon">€</div>
                  </div>

                </td>

                <td style="width: 160px" class="col-md-4 text-center">
                  <button *ngIf="isPayment == 'PAYMENT'" type="button" data-toggle="modal" class="btn btn-view btn-outline"
                    style="margin-right: 10px;"
                    [disabled]="!data.amountToPay"
                    (click)="payment(data,1,companyData.idCompany)">
                    <i class="fas fa-check"></i>{{data.idCompany}}
                  </button>

                  <button *ngIf="isPayment != 'EXTERNAL' && isPayment != 'CHECK'" type="button" data-toggle="modal" class="btn btn-info btn-outline"
                    [disabled]="!data.userPayments.length"
                    (click)="collapser(data)">
                    <i class="fas fa-list-ul"></i>
                  </button>

                </td>
              </tr>

              <tr *ngIf="data.isCollapsed == true && isPayment != 'CHECK'" style="background-color: gainsboro">
                <th colspan="3"></th>
                <th class="text-right">Versato il</th>
                <th class="text-right">Tipo</th>
                <th class="text-right">Importo</th>
                <th class="text-right">Da</th>
                <th colspan="3"></th>
              </tr>
          

              <tr *ngFor="let p of data.userPayments" style="background-color: ghostwhite">
                <ng-container *ngIf="(data.isCollapsed == true && isPayment != 'CHECK') || (isPayment == 'CHECK')" >
                  <td colspan="3"></td>
                  <ng-container *ngIf="isPayment == 'CHECK'" >
                    <td></td>
                    <td>{{data.user}}</td>
                    <td>{{data.transactionDate | date:'dd-MM-yyyy' }}</td>
                  </ng-container>
                  <td class="text-right">
                    {{p.balanceDate | date: 'dd-MM-yyyy'}}
                  </td>
                  <td class="text-right">
                    {{getPaymentTypesDescription(p.paymentType)}}
                  </td>
                  <td class="text-right">
                    {{p.amount | number : '1.2-2'}} €
                  </td>
                  <td class="text-right">
                    {{p.nameUserRecived}}
                  </td>

                  <td colspan="3" class="text-right">
                    <button type="button" data-toggle="modal" class="btn btn-danger btn-outline" (click)="deletePayment(p.id)" style="margin-right: 3.5rem; margin-top: 6px;">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>

          <tbody *ngIf="errorMessage === ''">
            <tr *ngIf="dataList?.length > 0 && (isPayment == 'PAYMENT' || isPayment == 'EXTERNAL')">
              <td *ngIf="isPayment == 'PAYMENT'" colspan="4" style="background-color: rgba(255, 255, 255, 0.9);"></td>
              <td *ngIf="isPayment == 'EXTERNAL'" colspan="3" style="background-color: rgba(255, 255, 255, 0.9);"><b>Totale venduto</b></td>
              
              <td class="text-right">
                <strong>{{totalAmount | number : '1.2-2'}} €</strong>
              </td>

              <td *ngIf="isPayment == 'PAYMENT'"  class="text-right">
                <strong *ngIf="totalPaid">{{totalPaid | number : '1.2-2'}} €</strong>
              </td>

              <td class="text-right">
                <strong *ngIf="totalSupplementAmount">{{totalSupplementAmount | number : '1.2-2'}} €</strong>
              </td>

              <td colspan=2></td>
  
            </tr>
          </tbody>


        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [maxSize]="10" [collectionSize]="listService.totalRows"
          [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>






<ng-template #template>

  <div tabindex="-1" role="dialog">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span (click)="close()">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Dettagli Versamenti </h4>

      </div>

      <br>

      <div *ngFor="let t of resumePayment">

        <div class="ibox-content">


          <div class="table-responsive">
            <table class="table shoping-cart-table">

              <tbody>
                <tr>

                  <td class="col-xs-6">
                    <h3>
                      <a class="text">
                        Data Versamento: {{t.balanceDate | date: 'dd-MM-yyyy'}}
                      </a>

                    </h3>
                    <h3>

                      <a class="text">
                        Data Vendita: {{t.paymentDate | date: 'dd-MM-yyyy'}}
                      </a>
                    </h3>

                  </td>


                  <td class="col-xs-4">
                    <h1>
                      {{t.amount}} €
                    </h1>
                  </td>

                  <td class="col-xs-2">

                    <button type="button" data-toggle="modal" class="btn btn-danger" (click)="deletePayment(t.id)">
                      <i class="fas fa-hand-holding-usd"></i>
                    </button>
                  </td>


                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>



      <div class="modal-footer">


      </div>
    </div>
  </div>



</ng-template>