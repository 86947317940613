<div class="row" style="padding-top: 20px"></div>
<div class="col-md-8 col-sm-offset-2">
  <div class="ibox float-e-margins ">
    <div class="ibox-title">
      <h2 class="col-xs-12">
        <strong>Wallet-Ricarica - {{nameResale}}</strong>
      </h2>

    </div>



<form  #formRecharge="ngForm">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">


            






            <div  class='form-group col-sm-6  col-lg-6'>
             
             
              <label for="rechargeTotalAmount"> Importo Ricarica </label>
              <div class="input-group col-md-6">

               
                <input type="text" id="rechargeTotalAmount" name="rechargeTotalAmount" placeholder="0.00" class="form-control" ngModel required rechargeTotalAmount  #rechargeTotalAmount="ngModel">
                <span class="input-group-addon">€</span>

              </div>
            </div>


            <div  class='col-sm-6  col-lg-6'>
              <div class="form-group">
                <label>Tipologia Pagamento</label>
                <div class='input-group col-md-12'>
                  <select type="text" id="paymentType" class="form-control" ngModel name="paymentType" required paymentType #rechargeTotalAmount="ngModel"
                    required>
                    <option [ngValue]="'TRANSFER'">Bonifico </option>
                    <option [ngValue]="'CHECK'">Assegno Bancario </option>
                    <option [ngValue]="'POSTAL_PAYMENT'">Pagamento Postale </option>
                    <option [ngValue]="'POS'">Carta di Credito </option>
                </select>
                </div>
              </div>
            </div>

            

        

            <div style="margin-top: 15px" class='col-sm-12  col-lg-12'>

                <button type="button" style="float: right" (click)="Recharge()" class="btn btn-redBU btn_new_element">
                  Ricarica</button>


                <button type="button" style="float: left" (click)="back()" class="btn btn-default btn_new_element">
                 Annulla</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  </div>

</div>