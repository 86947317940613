<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">




  <div class="ibox float-e-margins">
    <div class="ibox-title" style="display: flex; justify-content: space-between;">
      <h2>
        <strong>Analisi Incassi</strong>
      </h2>
      <div class='input-group'>
        <button *ngIf="listService.visibleFilter" type="button" style="margin-left: 10px" (click)="filterReset()" class="btn btn-view btn-outline">
          <i class="fa fa-rotate-left"></i>
        </button>
        <button type="button" style="margin-left: 10px" (click)="filterSearch()" class="btn btn-view btn-outline">
          Cerca
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">

          <div class="row col-lg-4 col-md-6 b-r ">
            <div class="row">
              <div class="col-lg-12">
                <div class="widget style1 lazur-bg" style="background-color: green">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fa fa-eur fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> {{descriptionAmount}}</span>
                      <h2 class="font-bold">
                        {{ (showDetailsFlag ? totalData.total.amount : totalAmount) | number : '1.2-2'}} €<br />
                        <span *ngIf="showDetailsFlag && totalData.total.supplementAmount">+ (suppl. {{totalData.total.supplementAmount | number : '1.2-2'}} €)</span>
                      </h2>
                      <small class="text-right">di cui:</small>
                      <h3 class="font-bold earning-detail">
                        Contanti: {{ (showDetailsFlag ? totalData.cash.amount : totalCash) | number : '1.2-2'}} €
                        <span *ngIf="showDetailsFlag && totalData.cash.supplementAmount">&nbsp;+ (suppl. {{totalData.cash.supplementAmount | number : '1.2-2'}} €)</span>
                      </h3>
                      <h3 class="font-bold earning-detail">
                        POS: {{ (showDetailsFlag ? totalData.pos.amount : totalPos) | number : '1.2-2' }} €
                        <span *ngIf="showDetailsFlag && totalData.pos.supplementAmount">&nbsp;+ (suppl. {{totalData.pos.supplementAmount | number : '1.2-2'}} €)</span>
                      </h3>
                      <h3 class="font-bold earning-detail">
                        ServicePay: {{ (showDetailsFlag ? totalData.spayg.amount : totalSpayg) | number : '1.2-2' }} €
                        <span *ngIf="showDetailsFlag && totalData.spayg.supplementAmount">&nbsp;+ (suppl. {{totalData.spayg.supplementAmount | number : '1.2-2'}} €)</span>
                      </h3>
                      <h3 class="font-bold earning-detail">
                        Altro: {{ (showDetailsFlag ? totalData.other.amount : totalOthers) | number : '1.2-2' }} €
                        <span *ngIf="showDetailsFlag && totalData.other.supplementAmount">&nbsp;+ (suppl. {{totalData.other.supplementAmount | number : '1.2-2'}} €)</span>
                      </h3>
                      <h3 class="font-bold" style="border-top: 1px solid white; padding-top: 6px;"> Prezzo medio: {{avgPrice | number : '1.2-2'}} €</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row col-lg-8 col-md-6 col-sm-12" style="margin-left: 5px">
            <form #formStatsEarn="ngForm">

              <div *ngIf="isSoluzione1" class='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                <div class="form-group">
                  <label>Azienda di trasporto</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" required idCompany #idCompany="ngModel"
                      required>
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let c of dataCompany" [ngValue]="c.idCompany">{{c.legalBusinessName}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-12 col-lg-4'>
                <div class="form-group">
                  <label>Incassi da - a:</label>
                  <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue"
                    ngModel bsDaterangepicker #bsDaterangepicker placement="right" [bsConfig]="bsConfig"
                    placement="bottom" autocomplete="off">
                </div>

                <ng-container *ngIf="withUserPaymentsFlag == false" >
                  <div class="form-group">
                    <label>Linea</label>
                      <div class='input-group col-lg-12  col-xs-12'>
                        <select type="text" id="idRoute" class="form-control" ngModel name="idRoute" required idRoute #idRoute="ngModel">
                          <option [ngValue]="'all'"> Tutti </option>
                          <option *ngFor="let d of dataRoute" [ngValue]="d.routeId">{{d.routeId}} - {{d.routeLongName}}</option>
                        </select>
                      </div>
                  </div>
                </ng-container>

                <div *ngIf="viewRiv" class="form-group">
                  <label>Rivendita</label>
                  <div class='input-group col-lg-12  col-xs-12'>
                    <select type="text" id="idResale" class="form-control" ngModel name="idResale" required idResale #idResale="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let r of dataResale" [ngValue]="r.id">{{r.legalBusinessName}} </option>
                    </select>
                  </div>
                </div>
        
                <div *ngIf="viewDk" class="form-group">
                  <label>Desk</label>
                  <div class='input-group col-lg-12  col-xs-12'>
                    <select type="text" id="idDesk" class="form-control" ngModel name="idDesk" required idDesk #idDesk="ngModel">
                      <option [ngValue]="'all'"> Tutte </option>
                      <option *ngFor="let d of dataDesk" [ngValue]="d.id">{{d.name}} </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class='col-xs-12 col-sm-6 col-md-6 col-lg-4'>
                <div class="form-group">
                  <label>Tipologia pagamento</label>
                  <div class='input-group col-xs-12'>
                    <select type="text" id="paymentType" class="form-control" ngModel name="paymentType" required paymentType #paymentType="ngModel"
                      required>
                      <option [ngValue]="'all'"> Tutti </option>
                      <option *ngFor="let pT of dataPaymentTypes" [ngValue]="pT.code">{{pT.description}}</option>
                    </select>
                  </div>
                </div>

                <ng-container>
                  <div class="form-group">
                    <label>Categoria</label>
                      <div class='input-group col-lg-12  col-xs-12'>
                        <select type="text" id="idFareCategoryGroup" class="form-control" ngModel name="idFareCategoryGroup" required idFareCategoryGroup #idFareCategoryGroup="ngModel">
                          <option [ngValue]="'all'"> Tutti </option>
                          <option *ngFor="let c of dataCategories" [ngValue]="c.groupId">{{c.name}}</option>
                        </select>
                      </div>
                  </div>
                </ng-container>
              </div> 

              <div class='col-xs-12 col-sm-6 col-md-6 col-lg-4'>
                <div class="form-group">
                  <div *ngIf="viewEc" class="form-group ">
                    <label>eCommerce</label>
                    <div class='input-group col-lg-12  col-xs-12'>
                      <select type="text" id="idEcommerce" class="form-control" ngModel name="idEcommerce" required idEcommerce #idEcommerce="ngModel">
                        <option [ngValue]="'all'"> Tutti </option>
                        <option *ngFor="let d of dataEcommerce" [ngValue]="d.idEcommerce">{{d.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                  <div class="col-sm-12">
                    <label>Canali di vendita</label>
                    <ng-container>
                      <div *ngFor="let chan of dataChannel; let i = index"  clas="col-md-6">
                        <div class="checkbox">
                          <input type="checkbox" name="listChannels" 
                          id="listChannels" listChannels 
                          [checked]=isCheckChannel(chan.idChannel)
                          (change)="setIdChannel($event,chan.idChannel)"
                          >
                          <label>
                            <b>{{chan.name}}</b>
                          </label>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </div>

              <div class="clearfix"></div>

              <div class='col-md-5 col-xs-12'>
                <div class="form-group">
                  <label class="text-success">Consulta dettagli supplemento</label>
                  <div class="checkbox">
                    <input type="checkbox" name="showDetails" id="showDetails" showDetails #showDetails (change)="checkboxAction($event, 1)">
                    <label>Visualizza dettagli </label>
                  </div>
                </div>
              </div>

              <div class='col-md-7 col-xs-12'>
                <div class="form-group">
                  <label class="text-success">Includi solo incassi VERSATI tramite "Versamento Cassa"</label>
                  <div class="checkbox">
                    <input type="checkbox" name="withUserPayments" id="withUserPayments" withUserPayments #withUserPayments (change)="checkboxAction($event, 2)">
                    <label>Includi versati </label>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div class="row text-center">
        <div class=" text-center">
          <div class="col-md-4">
            <div class="widget style1 lazur-bg btn-eCom" >
              <div class="row">
                <div class="col-xs-4">
                  <i class="fa fa-globe fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h4> E-Commerce</h4>
                  <h3 class="font-bold">
                    {{(showDetailsFlag ? (totalData.web.amount + totalData.quickBuy.amount) : (totalCommerce + totalQuickBuy)) | number : '1.2-2'}} €
                    <span *ngIf="showDetailsFlag && (totalData.web.supplementAmount || totalData.quickBuy.supplementAmount)">&nbsp;+ (suppl. {{(totalData.web.supplementAmount + totalData.quickBuy.supplementAmount) | number : '1.2-2'}} €)</span>
                  </h3>
                  <strong>
                    di cui
                    <span *ngIf="!showDetailsFlag">{{totalCoupon | number : '1.2-2'}}</span>
                    <span *ngIf="showDetailsFlag">
                      {{totalData.coupon.amount | number : '1.2-2'}}
                      <span *ngIf="totalData.coupon.supplementAmount">&nbsp;+ (suppl. {{totalData.coupon.supplementAmount | number : '1.2-2'}} €)</span>
                    </span>
                    € in coupon
                  </strong><br>
                  <small>
                    QuickBuy:
                    <span *ngIf="!showDetailsFlag">{{totalQuickBuy | number : '1.2-2'}}</span>
                    <span *ngIf="showDetailsFlag">
                      {{totalData.quickBuy.amount | number : '1.2-2'}}
                      <span *ngIf="totalData.quickBuy.supplementAmount">&nbsp;+ (suppl. {{totalData.quickBuy.supplementAmount | number : '1.2-2'}} €)</span>
                    </span>
                    €
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="widget style1 lazur-bg btn-opMob">
              <div class="row">
                <div class="col-xs-4">
                    <i class="fas fa-mobile-alt fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h4>Mobile</h4>
                  <h3 class="font-bold">
                    {{(showDetailsFlag ? totalData.mobileOperator.amount : totalOperatoreMobile) | number : '1.2-2'}} €
                    <span *ngIf="showDetailsFlag && totalData.mobileOperator.supplementAmount">&nbsp;+ (suppl. {{totalData.mobileOperator.supplementAmount | number : '1.2-2'}} €)</span>
                  </h3>
                  <strong>&nbsp;</strong><br>
                  <small>&nbsp;</small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="widget style1 lazur-bg btn-riv">
              <div class="row">
                <div class="col-xs-4">
                  <i class="fa fa-shopping-basket fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h4>Ricariche Rivend.</h4>
                  <h3 class="font-bold">
                    {{(showDetailsFlag ? totalData.reseller.amount : totalRivendita) | number : '1.2-2'}} €
                    <span *ngIf="showDetailsFlag && totalData.reseller.supplementAmount">&nbsp;+ (suppl. {{totalData.reseller.supplementAmount | number : '1.2-2'}} €)</span>
                  </h3>
                  <strong>&nbsp;</strong><br>
                  <small>&nbsp;</small>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="widget style1 sky-bg btn-desk">
              <div class="row">
                <div class="col-xs-4">
                    <i class="fas fa-store fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h4> Desk</h4>
                  <h3 class="font-bold">
                    {{(showDetailsFlag ? totalData.desk.amount : totalRivenditaInterna) | number : '1.2-2'}} €
                    <span *ngIf="showDetailsFlag && totalData.desk.supplementAmount">&nbsp;+ (suppl. {{totalData.desk.supplementAmount | number : '1.2-2'}} €)</span>
                  </h3>
                  <strong>&nbsp;</strong><br>
                  <small>&nbsp;</small>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="widget style1 lazur-bg btn-onTruck">
              <div class="row">
                <div class="col-xs-4">
                    <i class="fas fa-bus fa-5x"></i>
                </div>
                <div class="col-xs-8 text-right">
                  <h4>A Bordo</h4>
                  <h3 class="font-bold">
                    {{(showDetailsFlag ? totalData.onBoard.amount : totalABordo) | number : '1.2-2'}} €
                    <span *ngIf="showDetailsFlag && totalData.onBoard.supplementAmount">&nbsp;+ (suppl. {{totalData.onBoard.supplementAmount | number : '1.2-2'}} €)</span>
                  </h3>
                  <strong>&nbsp;</strong><br>
                  <small>&nbsp;</small>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-12 text-left">
          <br>
          <p class="bg-info p-sm">Il conteggio è relativo alle transazioni nette, calcolando gli eventuali rimborsi effettuati successivamente</p>
          <br>
        </div>

      </div>

      <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
        <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}</p>
      <div *ngIf="errorMessage === ''" class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th></th>
              <th>Canali di vendita</th>
              <th>Linea di rif.</th>
              <th>Rivendita</th>
              <th>Tipo di Pagamento</th>
              <th class="text-right">Importo</th>
            </tr>
          </thead>
          <tbody *ngIf="errorMessage === ''">
            <tr *ngFor="let data of dataList" style="cursor:pointer">
              <td style="width: 60px" class="col-md-4 text-center">
                <button style="width: 40px" title="{{data.channel}}" type="button" class="{{utils.checkColorChannel(data.channel)}}">
                  <i class="{{utils.checkIconChannel(data.channel)}}"></i>
                </button>
              </td>
              <td>{{(data.channel) || "Ricarica"}}</td>
              <td>{{data.idRoute}}</td>
              <td>{{data.resale}}</td>
              <td>{{getPaymentType(data.paymentType)}}</td>
              <td class="text-right">
                {{(showDetailsFlag ? data.amount : data.totalAmount) | number : '1.2-2'}} €<br />
                <span *ngIf="showDetailsFlag && data.supplementAmount">&nbsp;+ (suppl. {{data.supplementAmount | number : '1.2-2'}} €)</span>
              </td>
            </tr>
            <tr *ngIf="totalNonVersato >0">
              <td>
                <button style="width: 40px" title="Non versato" type="button" class="btn btn-warning">
                  <i class="fa fa-exclamation-circle"></i>
                </button>
              </td>
              <td colspan="4">Non versati</td>
              <td class="text-right">
                {{(showDetailsFlag ? totalData.notPaid.amount : totalNonVersato) | number : '1.2-2'}} €<br />
                <span *ngIf="showDetailsFlag && totalData.notPaid.supplementAmount">&nbsp;+ (suppl. {{totalData.notPaid.supplementAmount | number : '1.2-2'}} €)</span>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
          [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
        </ngb-pagination>
      </div>

    </div>
  </div>
</div>